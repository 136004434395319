import React from "react";
import { images } from "../config/ConstImg";
import fast from '../icons/act.png';
import disruption from '../icons/disruption.png';
import trade from '../icons/trade.png';
import trustworthy from '../icons/trustworthy.png';
import Testimonials from './Testimonials'
import { useNavigate } from "react-router-dom";

export const HomePage = () => {

    const navigate = useNavigate();
    return (
        <div className="container">
            <div className="mt-5">
                <div className="row mt-5">
                    <div className="text-center mt-4 mb-4">
                        <h3 className="home-service-content-text-box-txt my-2">Insta-guard <span className="app-color">Mission Statement</span> </h3>
                    </div>

                    <div className="col-12 start-text home-page-content">
                        <p>
                            At Insta-guard, our mission is to make private security accessible and affordable for everyone, especially in a time when police numbers are declining. We believe that safety is a right, not a privilege, and we are committed to filling the void by providing a trusted, on-demand security solution for those who need it most.
                        </p>
                        <p>
                            Our platform serves as a safety net for residents, vulnerable individuals, and businesses, ensuring that professional security is available at a moment’s notice. Whether it’s deterring crime, offering protection, or providing rapid response security, Insta-guard connects you to vetted, reliable, and geo-fenced security professionals in real-time.
                        </p>
                    </div>
                    <div className="d-flex justify-content-center align-items-center my-5 flex-column text-center">
                        <div className="col-12 col-md-6">
                            <img
                                src={images.founder}
                                className="img-fluid rounded-3  image-size"
                                alt="Founder"
                            />
                        </div>
                        <p className="mt-3 fw-bold text-muted">Founder and Director of Insta-guard – Sir Francis Charles Jones</p>
                    </div>


                    <div className="text-center my-5">
                        <div className="text-center pillar-head">
                            <h3 className="home-service-content-text-box-txt">Our <span className="app-color"> Four Pillars </span></h3>
                        </div>

                        <p className="section-subtitle"></p>
                        <div className="row justify-content-center">
                            <div className="col-md-3 mb-4">
                                <div className="step-card">
                                    <div className="step-icon">
                                        <img src={trade} alt="Step 1 Icon" />
                                    </div>
                                    <h5 className="step-title base-color">Affordability</h5>
                                    <p className="step-text app-color">Security should be  <b>cost effective</b> for all, not just a select few.</p>
                                </div>
                            </div>
                            <div className="col-md-3 mb-4">
                                <div className="step-card">
                                    <div className="step-icon">
                                        <img src={fast} alt="Step 2 Icon" />
                                    </div>
                                    <h5 className="step-title  base-color">Speed</h5>
                                    <p className="step-text app-color">Instant access to <b>professional</b> security when you need it.</p>
                                </div>
                            </div>
                            <div className="col-md-3 mb-4">
                                <div className="step-card">
                                    <div className="step-icon">
                                        <img src={trustworthy} alt="Step 3 Icon" />
                                    </div>
                                    <h5 className="step-title  base-color">Reliability</h5>
                                    <p className="step-text app-color">A nationwide network of <b>trusted</b>, DBS-checked security <b>experts</b>.</p>
                                </div>
                            </div>
                            <div className="col-md-3 mb-4">
                                <div className="step-card">
                                    <div className="step-icon">
                                        <img src={disruption} alt="Step 4 Icon" />
                                    </div>
                                    <h5 className="step-title  base-color">Innovation</h5>
                                    <p className="step-text app-color">Using cutting-edge technology to bridge the gap in security services.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="row row-reverse mt-5">
                    <div className="col-12 col-md-6 image-contain">
                        <img src={images.payas} className="img-fluid rounded-3" alt="Secure Payment" />
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="about-us-box-play-box01-left">
                            <p className="home-service-content-text-box-txt  my-2">
                                Why Choose Us?
                            </p>
                            <p className="custom-head app-color">Insta-guard offers personalized security solutions tailored to user needs.</p>
                            <ul className="base-color list-font-size">
                                <li className="list-bottom"><b>Save Thousands Monthly:</b> <span className="app-desc-txt-color"> Reduce security expenses while enhancing operational efficiency.</span></li>
                                <li className="list-bottom"><b>Affordable Rates:</b> <span className="app-desc-txt-color">Starting from £13.57 per hour, ensuring quality security at lower costs.</span></li>
                                <li className="list-bottom"><b>Real-Time Monitoring:</b>  <span className="app-desc-txt-color"> Geo-fencing, GPS tracking, and automated monitoring for security professionals.</span></li>
                                <li className="list-bottom"><b>Secure Communication:</b> <span className="app-desc-txt-color"> Use our VOIP system to contact security staff without sharing personal numbers.</span></li>
                                <li className="list-bottom"><b>Thousands of Professionals:</b>  <span className="app-desc-txt-color">Access a large network of vetted, DBS-checked security experts. </span></li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* <Testimonials /> */}
                
                <p className="home-service-content-text-box-txt text-center mt-4">
                Testimonials
                    </p>
                <div className="row mt-2">
                    <p className="testimonial-head  text-center mb-4">
                         <span className="app-color"> Harassment Notice</span>
                    </p>
                    <div className="d-flex justify-content-center my-5">
                        <div className="col-12  image-contain">
                            <img src={images.stop} className="img-fluid rounded-3 image-set shadow-lg" alt="Freelancer Platform" />
                        </div>
                    </div>
                    <div className="col-12 col-md-12  app-desc-txt-color  list-font-size">
                        <p>
                            "After enduring relentless harassment from my neighbours—who went as far as recording my family on video and audio, even pointing cameras into my bathroom—I knew I had to take action. Despite repeated reports, the police always seemed to take their side, leaving us feeling helpless.
                        </p>
                        <p>
                            That’s when we decided to hire a private investigator to gather undeniable evidence of their behaviour. Thanks to their expertise, these ‘professionals’ were finally served with harassment notices. It was a huge relief to know that justice was being done and that we could reclaim our privacy and peace of mind.
                        </p>
                        <p>
                            For anyone facing similar harassment, I can’t stress enough the importance of gathering solid evidence—it truly makes all the difference."
                        </p>
                    </div>


                </div>

                <div className="row mt-5">


                    <p className="testimonial-head text-center my-4">
                        <span className="app-color"> Catching Fraudsters</span>
                    </p>

                    <div className="d-flex justify-content-center my-5">
                        <div className="col-12  image-contain">
                            <img src={images.fraud} className="img-fluid rounded-3 image-set shadow-lg" alt="Freelancer Platform" />
                        </div>
                    </div>

                    <div className="col-12 col-md-12  app-desc-txt-color  list-font-size">
                        <p>
                            "When one of our brick workers claimed to be off sick while still receiving sick pay from us, we suspected something wasn’t right. We decided to hire an investigator through Insta-guard to uncover the truth.
                        </p>
                        <p>
                            Thanks to their expertise, we obtained clear evidence of him working on another site while falsely claiming sickness. The investigation was handled discreetly and professionally, giving us the proof we needed to act.
                        </p>
                        <p>
                            Insta-guard provided a fast, reliable, and efficient service—highly recommended for anyone needing security and investigative solutions!"
                        </p>
                    </div>

                </div>


                <div className="row mt-5">


                    <p className="testimonial-head text-center my-4">
                         <span className="app-color"> Home Security in London</span>
                    </p>

                    <div className="d-flex justify-content-center my-5">
                        <div className="col-12  image-contain">
                            <img src={images.circleimg} className="img-fluid rounded-3 image-set" alt="Freelancer Platform" />
                        </div>
                    </div>

                    <div className="col-12 col-md-12 app-desc-txt-color  list-font-size">
                        <p>
                            "I was amazed at how simple and efficient the Insta-guard service was. I didn’t have to speak to anyone—I just filled out the request on the app, and everything was handled seamlessly.
                        </p>
                        <p>
                            I even received notifications when the guard arrived and left, which gave me real peace of mind. The entire process felt effortless, and I couldn’t believe something like this had been invented!
                        </p>
                        <p>
                            For just a small fee, I received an <b>excellent service</b>, and <b>I can’t recommend Insta-guard enough</b> for anyone needing fast and professional security."
                        </p>
                    </div>

                </div>

                <div className="row mt-5">


                    <p className="testimonial-head text-center my-4">
                         <span className="app-color"> Security for a Dental Practice in Leeds</span>
                    </p>

                    <div className="d-flex justify-content-center my-5">
                        <div className="col-12  image-contain">
                            <img src={images.clean} className="img-fluid rounded-3 image-set shadow-lg" alt="Freelancer Platform" />
                        </div>
                    </div>

                    <div className="col-12 col-md-12 app-desc-txt-color  list-font-size">
                        <p>
                        “As a dentistry in Leeds, we faced an unsettling situation when a former partner left the company and attempted to return to take belongings. I wanted to ensure safety and peace of mind for my team and patients.
                        </p>
                        <p>
                        I reached out to Insta-guard, and they provided two plain-clothed security officers who handled the situation discreetly and professionally. Their presence gave us all confidence and reassurance, allowing us to focus on our work without worry.
                        </p>
                        <p>
                        The service was fast, efficient, and exactly what we needed. I highly recommend Insta-guard for any business looking for reliable security solutions."
                        </p>
                    </div>

                </div>

                <div className="row mt-5 ">


                    <p className="testimonial-head text-center my-4">
                         <span className="app-color"> Wedding Security with Insta-guard</span>
                    </p>


                    <div className="col-12 col-md-12  app-desc-txt-color  list-font-size">
                        <p>
                        "My daughter’s wedding was supposed to be a joyous occasion, but her ex-partner had threatened to turn up and ruin the ceremony. We couldn’t take any chances, so we turned to Insta-guard for security.
                        </p>
                        <p>
                        Their team arrived suited and booted, with one dog handler in the car park and another inside the hotel. While the hotel allowed the ex-partner entry due to their own policies, we had the added protection of two experienced security professionals, ensuring the day remained peaceful.
                        </p>
                        <p>
                        Thanks to Insta-guard, the event went ahead smoothly, and everyone felt safe and reassured. Their presence gave us the confidence and peace of mind we needed.
                        </p>
                        <p>A huge thank you to Insta-guard for making sure my daughter’s big day stayed special!"</p>
                        
                    </div>

                </div>



                {/* <div className="row mt-5">


                    <p className="testimonial-head app-color  text-center my-4">
                        Motorbike Gangs and Vandalism
                    </p>
                    <div className="col-12 col-md-12  app-desc-txt-color  list-font-size">
                        <p>
                            “Motorbike Gangs, Arson Attacks, and the Power of Investigation – There’s Always a Trail”
                        </p>
                        <p>
                            In Kent, masked motorbike gangs have been carrying out arson attacks, causing explosions near residential homes. It’s a terrifying reality, but one thing is certain—there is always a trace.
                        </p>
                        <p>
                            Criminals don’t operate in a vacuum. They start somewhere, pass someone’s CCTV, and leave behind clues—whether they realize it or not. People notice things. Someone, somewhere, has seen something unusual or suspicious.
                        </p>

                        <h4 className="app-color my-3">The investigator’s job is to:</h4>
                        <ul className="base-color list-font-size">
                            <li className="list-bottom"><b>Collate the evidence:</b><span className="app-desc-txt-color">Gathering statements, examining behaviours, and cross-referencing data.</span></li>
                            <li className="list-bottom"><b>Knock on doors :</b><span className="app-desc-txt-color"> Speaking to witnesses and uncovering overlooked details.</span></li>
                            <li className="list-bottom"><b>Review CCTV footage:</b><span className="app-desc-txt-color"> Piecing together movements and tracking suspects.</span></li>
                            <li className="list-bottom"><b>Join the dots :</b><span className="app-desc-txt-color">  Because criminals make mistakes, and those mistakes lead to the truth.</span></li>
                        </ul>

                        <p><span className="fw-bold">Crime always leaves a footprint.</span> At Insta-guard, our investigators are trained to find it.</p>
                        <p className="fw-bold">Need an investigator? Let Insta-guard uncover the truth.</p>

                    </div>

                </div> */}


                <div className="text-center mt-5">
                    <p className="fw-bold base-color ig-slogan" style={{ fontSize: "30px", fontStyle:"oblique" }}>“When Safety Matters, Price Shouldn’t – We Make It Affordable.”</p>
                    <p className="fw-bold app-color ig-text" style={{ fontSize: "24px" }}>CEO & Founder: Sir Francis Charles Jones</p>
                </div>


                <div className="row mt-5">
                    <p className="home-service-content-text-box-txt  text-center my-5">
                        Why We’re Front-runners in the Security Game
                    </p>

                    <div className="col-12 col-md-12 image-contain">
                        <img src={images.doghandling} className="img-fluid rounded-3 image-set shadow-lg" alt="Freelancer Platform" />
                    </div>
                    <div className="col-12 col-md-12">
                        <div className="about-us-box-play-box01-left  app-desc-txt-color">

                            <h4 className="my-3 app-color">We think outside the box – If there is a Smarter Way our research team are on it</h4>
                            <p className="base-color list-font-size">
                                Insta-guard is reinventing security through an ‘ON DEMAND’ marketplace model, allowing security professionals to set their own rates and customers to choose the best fit. This flexibility and transparency put control in the hands of both clients and security professionals.

                            </p>
                            <p>At Insta-guard, our vision is clear: to build a trusted network of private security professionals, safeguarding our streets, rural communities, and towns.</p>

                            <p>
                                We believe in fair pricing – no extra costs, just pure value. Our mission is not just about security; it’s about restoring peace, protecting the vulnerable, and being a present help in times of trouble.
                            </p>

                            <p>The devil may sow his seed, but God always has the final word. That’s why we stand ready, ensuring that when people need help, hope is on its way.</p>
                        </div>
                    </div>

                </div>

                <div className="row my-5 text-center  app-desc-txt-color" >
                    <p className="home-service-content-text-box-txt  my-2">
                        Hire Professional Security – One Click Away!
                    </p>
                    <p>
                        Our DNA is reflected in our company name Psalm 23 Ltd trading as Insta-guard
                    </p>

                    <div className="col-12 col-md-12 image-contain">
                        <img src={images.doghandler} className="img-fluid rounded-3 image-set shadow-lg" alt="Freelancer Platform" />
                    </div>

                </div>

                <div className="text-center">
                <p className="app-color fw-bold" style={{fontSize:"24px"}}>Instant Security, Anytime, anywhere</p>
                     <button className="book-aguard-btn" onClick={() =>  navigate("/services")}>
            Book Now
         </button>
                </div>


                <div className="row mt-5">
                    <div className="col-12 col-md-12">

                        <p className="home-service-content-text-box-txt ">
                            How to<span className="app-color"> Get Started</span>
                        </p>


                        <blockquote className="blockquote">
                            <p className="base-color start-text">
                                “Insta-guard is designed to transform how security is ordered, creating a more efficient, cost-effective model that benefits both businesses and local communities. By connecting clients directly with vetted security professionals, we reduce overhead costs, allowing for more competitive pricing without compromising on quality. This approach boosts local employment opportunities, ensuring money stays within the community and supports the local economy. With Insta-guard, businesses get fast, reliable security, and professionals gain more job opportunities—strengthening security while driving economic growth.”
                            </p>
                        </blockquote>

                        <div>
                            <ol className="base-color list-font-size">
                                <li className="list-bottom"><b>Select Your Service: </b><span className="app-desc-txt-color">Choose the type of security you need</span></li>
                                <li className="list-bottom"><b> Set Your Requirements: </b><span className="app-desc-txt-color"> Input the dates, times, and specific job details.</span></li>
                                <li className="list-bottom"><b>Review & Hire: </b><span className="app-desc-txt-color"> Instantly connect with local security professionals who meet your criteria.</span></li>
                            </ol>

                        </div>
                    </div>
                </div>
            </div>
        </div>



    );
};
