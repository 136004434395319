import { Box,  Typography, CircularProgress, Grid } from "@mui/material";
import { CheckCircleOutlined,CloseCircleOutlined } from "@ant-design/icons"; 
import React from 'react'
import { Button } from "@mui/material";

const SecureBookingModalLoad = ({type,handleClose}) => {
    switch(type){
        case "load":
            return(
                <div style={{ animation: "fadeIn 0.5s ease-in-out",height:"100%"}} className="d-flex justify-content-center flex-column align-items-center">
                    <CircularProgress sx={{ color: "#2a2f83", mb: 2 }} />
                    <Typography variant="h6" sx={{ fontWeight: "bold", color: "#832b67" }}>
                        Your request is processing please wait!.
                    </Typography>
                </div>
            )
        case "success":
            return(
                <>
                <div style={{ marginTop: "50px", animation: "fadeIn 0.5s ease-in-out",height:"80%" }}  className="d-flex justify-content-center flex-column align-items-center">
                    <CheckCircleOutlined style={{ fontSize: "80px", color: "#28a745" }} />
                    <h2 style={{ color: "#333", marginTop: "20px" }}>Thank You!</h2>
                    <p style={{fontWeight: '700'}} >Your response has been submitted successfully.</p>
                    <p className="app-color">Thanks for your submission!  We'll contact you soon.</p>
                </div>
                <div className="d-flex justify-content-end">
                <Button onClick={handleClose}   variant="contained" style={{  backgroundColor: "#2a2f83"}}>
                    Close
                </Button>
                </div>
                </>
            )
        case "failure":
            return(
                <>
                <div style={{ marginTop: "50px", animation: "fadeIn 0.5s ease-in-out",height:"80%" }}  className="d-flex justify-content-center flex-column align-items-center">
                    <CloseCircleOutlined style={{ fontSize: "80px", color: "red" }} />
                    <h2 style={{ color: "#333", marginTop: "20px" }}>OOPS!</h2>
                    <p style={{fontWeight: '700'}} >Something Went Wrong.</p>
                    <p className="app-color">Try Again Later!.</p>
                </div>
                <div className="d-flex justify-content-end">
                      <Button onClick={handleClose}   variant="contained" style={{  backgroundColor: "#2a2f83"}}>
                          Close
                      </Button>
                </div>
                </>
            )

    }
}

export default SecureBookingModalLoad
