import React, { useEffect, useState } from 'react';
import { FaLocationDot } from "react-icons/fa6";
import { GoSearch } from "react-icons/go";
import { FaSearch } from "react-icons/fa";
import Question from './Question';
import AutoCompleteAntd from '../View/AutoCompleteAntd';
import FetchApi from '../config/ApiCal';
import { Box, Grid, Typography, Button, Container } from "@mui/material";


const SearchServices = ({ isModalOpen, setIsModalOpen, selectedService, setSelectedService, setSelectedPost, selectedPost }) => {

  const [error, setError] = useState(false);
  const [qa, setQa] = useState([]);
  const [loading, setLoading] = useState(true);
  const [subQa, setSubQa] = useState([]);
  const [postshowModal, setPostShowModal] = useState(false);
  const [contactMessage, setContactMessage] = useState('');


  const serviceSearch = {
    api: 'service_list', placeholder: 'What security service are you looking for ?',
    icon: <GoSearch style={{ color: '#000', marginRight: "10px" }} />,
    setSelect: setSelectedService,
    selectedData: selectedService
  };

  const postcodSearch = {
    api: 'location', placeholder: 'Post code',
    icon: <FaLocationDot style={{ color: '#000', marginRight: "5px" }} />,
    setSelect: setSelectedPost,
    selectedData: selectedPost
  };


  const handleSearchClick = () => {
    if (selectedService?.id) {
      setError(false);
      setIsModalOpen(true);
      setLoading(true);
      getServices();
    } else {
      setError(true);
    }
  }

  const getServices = async () => {
    try {
      const res = await FetchApi('questionAnswerBasedCat', { id: selectedService?.id });
      if (res?.message === 'success') {
        const q1 = res?.category_questions;
        const q2 = res?.sub_category;
        setSubQa(q2);
        setQa(q1);
        setContactMessage(res?.contact_msg || '');
        if (!selectedPost) {
          setPostShowModal(true);
        }
      }

    } catch (err) {
      console.log('Error fetching services', err);
    } finally {
      setLoading(false);
    }
  }

  return (
   
    <>
      <Container maxWidth="md" sx={{ mt: 10, mb: 19 }}>
        <Box>
          {/* Headings */}
          <Typography variant="h2" sx={{ fontWeight: 600, fontSize: { xs: "28px", md: "40px" } }} className="app-color mb-3">
            Hire Instant, Affordable, and Trusted Security Services
          </Typography>
          <Typography variant="h3" sx={{ fontWeight: 600, fontSize: { xs: "22px", md: "32px" } }} className="base-color mb-3">
          Anytime, Anywhere!
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: 600, color: "#797A7C", fontSize: { xs: "16px", md: "20px" }, mb: 4 }}>
          We can help you today
          </Typography>

          {/* Search Fields */}
          <Grid container spacing={2} justifyContent="center" alignItems="flex-start">
            {/* Service Input */}
            <Grid item xs={12} md={7}>
              <Box sx={{ position: "relative" }}>
                <AutoCompleteAntd searchData={serviceSearch} fullWidth />
                {error && (
                  <Typography color="error" sx={{ mt: 1, fontSize: "14px" }}>
                    * Please select a service
                  </Typography>
                )}
              </Box>
            </Grid>

            {/* Postcode Input */}
            <Grid item xs={12} md={3}>
              <AutoCompleteAntd searchData={postcodSearch} fullWidth />
            </Grid>

            {/* Search Button */}
            <Grid item xs={12} md={2} sx={{ display: "flex", justifyContent: { xs: "center", md: "flex-start" } }}>
              <Button
                onClick={handleSearchClick}
                variant="contained"
                sx={{
                  width: { xs: "auto", md: "100%" },
                  minWidth: "120px",
                  height: "46px",
                  mt: { xs: 2, md: 0 },
                  borderRadius: "5px",
                  fontSize: "16px",
                  boxShadow: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#2a2f83",
                  color: "#fff",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#3b40a5",
                  },
                }}
              >
                <span className="search-text">Search</span>
              </Button>
            </Grid>
          </Grid>

          {/* Popular Services */}
          <Typography sx={{ fontWeight: 550, mt: 3, color: "rgb(121, 122, 124)", fontSize: "14px" }}>
            Popular: Door Supervisor, Security Guards
          </Typography>
        </Box>
      </Container>

      {
        selectedService && selectedService?.id &&

        <Question
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          selectedService={selectedService}
          selectedPost={selectedPost}
          postcodSearch={{ ...postcodSearch, showPost: postshowModal }}
          loading={loading} setLoading={setLoading}
          qa={qa}
          subQa={subQa}
          setQa={setQa}
          setSubQa={setSubQa}
          contactMessage = {contactMessage}
        />
      }
    </>




  );
};

export default SearchServices;
