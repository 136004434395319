import playstore from "../asserts/google.png";
import appstore from "../asserts/apple.png";
import "../styles/appLinks.css";
import { Grid } from "antd";
import { FaApple, FaAppStore, FaAppStoreIos, FaGooglePlay } from "react-icons/fa";
import { images } from "../config/ConstImg";

const AppLinks = () => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const isMobile = screens.xs || screens.sm; 
  const isLaptop = screens.md || screens.lg || screens.xl;
  return (
    <div className="app_links m-1">
        <div className="">
        <p className="app-color contact-text">Download our app today
          <span> get instant security anytime</span> </p>
        <ul>
        <li className="link_item">
        <a href="https://play.google.com/store/apps/details?id=com.instaguard.user" target="blank">
        {isLaptop?
        <img  style={{width: "130px"}} src={playstore} alt="playstore"/>
        :
        // <div
        // style={{display: "flex",
        //   alignItems: "center",
        //   justifyContent: "center",
        //   width: "40px",
        //   height: "40px",
        //   backgroundColor: "rgba(0,0,0,0.1)",
        //   borderRadius: "10px",}}
        // >
        //   <FaGooglePlay size={25} color="#000"/>
        // </div>
        <img className="mobile-icon rounded-3" src={images.googlePlayStoreIcon} style={{width:"40px"}}/>
        }
        </a>
        </li>
       <li className="link_item">
        <a href="https://apps.apple.com/in/app/insta-guard/id6479236411" target="blank">
        {isLaptop?
        <img style={{width: "130px"}} src={appstore} alt="appstore"/>
        :
        // <div
        // style={{display: "flex",
        //   alignItems: "center",
        //   justifyContent: "center",
        //   width: "40px",
        //   height: "40px",
        //   backgroundColor: "rgba(0,0,0,0.1)",
        //   borderRadius: "10px",}}
        // >
        //   <FaApple size={25} color="#000"/>
        // </div>
        <img className="mobile-icon rounded-3" src={images.appStoreIcon} style={{width:"40px"}}/>
        }
        </a>
       </li>
        </ul>
        </div>
  </div>
  )
}

export default AppLinks
