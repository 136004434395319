import { Box, Button, Grid, IconButton, Modal, Typography } from '@mui/material'
import React, { useState } from 'react'
import { FaClock, FaEnvelope,  FaPhoneAlt, FaTimesCircle, FaUser, FaUserShield } from 'react-icons/fa'
import {  FaLocationDot, FaShield} from 'react-icons/fa6'
import CustomAutoComplete from '../View/CustomAutocomplete'
import "../styles/secureBookingModal.css"
import CustomDatePicker from './CustomDatePicker' 
import BookingButton from './BookingButton';
import SecureBookingModalLoad from './SecureBookingModalLoad';
import FetchApi from '../config/ApiCal';
const SecureBookingModel = ({questionModal}) => {
  const [secureBookingModal,setSecureBookingModal]=useState(false)
  const [postCode,setPostCode]=useState(null)
  const [service,setSelectedService]=useState(null)
  const [guardCount,setGuardCount]=useState(null)
  const [shiftLength,setShiftLength]=useState(null)
  const [date,setDate]=useState(null)
  const [time,setTime]=useState(null)
  const [name,setName]=useState(null)
  const [email,setEmail]=useState(null)
  const [phone,setPhone]=useState(null)
  const [formLoading,setFormLoading]=useState(false)
  const [submitStatus,setSubmitStatus]=useState("")
  const [errors,setErrors]=useState({})
  const selectGuardOptions=[{value:'1'},{value:"2"},{value:"3"},{value:"4"},{value:"5"},{value:"6"},{value:"7"}
    ,{value:"8"},{value:"9"},{value:"10+"}
  ]
  const handleClose=()=>{
    setSecureBookingModal(false)
    setErrors({})
    // setFormLoading(false)
  }
  const postcodSearch = {
    api: 'location', placeholder: 'Enter Address or Postcode',
    icon: <FaLocationDot style={{ color: '#000', marginRight: "10px" }} />,
    setSelect: setPostCode,
    selectedData: postCode,
    name:"postcode",
    error:errors["postcode"]
  };
  const serviceSearch = {
    api: 'service_list', placeholder:'Door Supervisor, Close Protection, K9 Unit, Event Security, Private Investigator etc.',
    icon: <FaShield style={{ color: '#000', marginRight: "10px" }} />,
    setSelect: setSelectedService,
    selectedData: service,
    name:"service"
  };
  const guardSearch = {
    data:selectGuardOptions, placeholder:'Choose from 1 to 10+',
    icon: <FaUserShield style={{ color: '#000', marginRight: "10px" }} />,
    setSelect: setGuardCount,
    selectedData: guardCount,
    name:"guardcount"
  };
  const hoursRequired={
    data:"", placeholder:'Specify Shift Length or 24/7 Coverage',
    icon: <FaClock style={{ color: '#000', marginRight: "10px" }} />,
    setSelect: setShiftLength,
    selectedData: shiftLength,
    name:"hoursrequired"

  }
  const userName={
    data:"", placeholder:'Enter Name',
    icon: <FaUser style={{ color: '#000', marginRight: "10px" }} />,
    setSelect: setName,
    selectedData: name,
    name:"username"
  }
  const userEmail={
    data:"", placeholder:'Enter Email',
    icon: <FaEnvelope style={{ color: '#000', marginRight: "10px" }} />,
    setSelect: setEmail,
    selectedData: email,
    name:"useremail"

  }
  const userPhone={
    data:"", placeholder:'Enter Phone',
    icon: <FaPhoneAlt style={{ color: '#000', marginRight: "10px" }} />,
    setSelect: setPhone,
    selectedData: phone,
    name:"userphone",
    error:errors["userphone"]

  }

  const inputValidation=(data)=>{
    let valid=true
    if(!data.service){
      valid=false
      setErrors((prev)=>({...prev,service:"Select Valid Security"}))
    }
    else
      setErrors((prev)=>({...prev,service:null}))
    if(!data.location){
      valid=false
      setErrors((prev)=>({...prev,postcode:"Enter Valid Location"}))
    }
    else
      setErrors((prev)=>({...prev,postcode:null}))
    if(!data.guardcount){
      valid=false
      setErrors((prev)=>({...prev,guardcount:"Select Valid Guard Count"}))
    }
    else
      setErrors((prev)=>({...prev,guardcount:null}))
    
    const shiftPattern=/^(24\/7|\d+)$/
    if(!data.shiftLength || !shiftPattern.test(data.shiftLength) ){
      valid=false
      setErrors((prev)=>({...prev,hoursrequired:"Enter Valid Shift Length"}))
    }
    else
      setErrors((prev)=>({...prev,hoursrequired:null}))

    const namePattern=/^[A-Za-z\s]+$/
    if(!data.name || !namePattern.test(data.name)){
      valid=false
      setErrors((prev)=>({...prev,username:"Enter Valid Name"}))
    }
    else
      setErrors((prev)=>({...prev,username:null})) 

    const emailPattern=/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if(!data.email || !emailPattern.test(data.email)){
      valid=false
      setErrors((prev)=>({...prev,useremail:"Enter Valid Email"}))
    }
    else
      setErrors((prev)=>({...prev,useremail:null})) 

    const phonePattern=/^\+?[0-9]{7,15}$/
    if(!data.mobile || !phonePattern.test(data.mobile)){
      valid=false
      setErrors((prev)=>({...prev,userphone:"Enter Valid Phone"}))
    }
    else
      setErrors((prev)=>({...prev,userphone:null})) 

    if(!data.date){
      valid=false
      setErrors((prev)=>({...prev,date:"Enter Valid Date"}))
    }
    else
      setErrors((prev)=>({...prev,date:null})) 
    
    
    if(!data.time){
      valid=false
      setErrors((prev)=>({...prev,time:"Enter Valid Time"}))
    }
    else
      setErrors((prev)=>({...prev,time:null}))
    

    return valid
    
  }
  const submitBookingForm=async(e)=>{
    e.preventDefault()
    const submitData={name,email,mobile:phone,location:postCode?.value,service:service?.value
      ,guardcount:guardCount?.value,shiftLength,date,time
    }
    if(inputValidation(submitData)){
      setFormLoading(true)
      setSubmitStatus("load")
      await FetchApi("secureBooking",submitData)
      .then((data)=>{
        setSubmitStatus("success")
      }).catch((error)=>{
        setSubmitStatus("failure")
      }).finally(()=>{
        setPostCode(null)
        setSelectedService(null)
        setGuardCount(null)
        setShiftLength(null)
        setDate(null)
        setTime(null)
        setName(null)
        setEmail(null)
        setPhone(null)
        setTimeout(()=>{
          setFormLoading(false)
          setSecureBookingModal(false)
        },5000)
      })
    }
  }

  return (
    <>
    <BookingButton setSecureBookingModal={setSecureBookingModal} secureBookingModal={secureBookingModal} questionModal={questionModal}/>  
    <Modal open={secureBookingModal} onClose={handleClose}
    style={{
      zIndex:1
    }}
    >
    <Box
      sx={{
        position: "absolute",
        top: "20%",
        left: "50%",
        transform: "translate(-50%, -20%)",
        // minWidth: { xs: "80%", md: "auto" },
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        width:{xs:"80vw",sm:"60vw",md:550},
       height:{xs:"85vh",sm:"75vh",md:600}
        // borderRadius: 2,
      }}
    >
        <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 5,
              right:8,
              color: "grey.600",
            }}
          >
          <FaTimesCircle/>
          </IconButton>
      {!formLoading?<>
      <Typography variant="h2" sx={{ fontWeight: 600, fontSize: { xs: "18px", md: "20px" } }} className="app-color mb-3">
        Fill in the details below, and our expert security team will be on standby
      </Typography>
      <form onSubmit={submitBookingForm} style={{height:"85%"}}>
      <Grid container mt={1} spacing={4} justifyContent="" alignItems="flex-start" sx={{height:"90%"}} overflow={"auto"} className='content-container'>
 
        <Grid item xs={12} >
          <Box sx={{ position: "relative" }}>
            <Typography  mb={1}  variant="h5" sx={{ fontWeight: 400, fontSize: { xs: "16px", md: "18px" } }}>
              Location
            </Typography> 
            <CustomAutoComplete searchData={postcodSearch} fullWidth securebooking={true} currentError={errors} />
          </Box>
        </Grid>
        <Grid item xs={12} >
            <Box sx={{ position: "relative" }}>
              <Typography  mb={1}  variant="h5" sx={{ fontWeight: 400, fontSize: { xs: "16px", md: "18px" } }}>
              Type of Security Needed
              </Typography> 
              <CustomAutoComplete searchData={serviceSearch} fullWidth securebooking={true} currentError={errors}/>
            </Box>
        </Grid>
        <Grid item xs={12} >
            <Box sx={{ position: "relative" }}>
              <Typography  mb={1}  variant="h5" sx={{ fontWeight: 400, fontSize: { xs: "16px", md: "18px" } }}>
              Hours Required
              </Typography> 
              <CustomAutoComplete searchData={hoursRequired} fullWidth securebooking={true} customSelect={true} customInput={true} currentError={errors}/>
            </Box>
        </Grid>
        <Grid item xs={12} >
            <Box sx={{ position: "relative" }}>
              <Typography mb={1} variant="h5" sx={{ fontWeight: 400, fontSize: { xs: "16px", md: "18px" } }}>
              Number of Guards Needed
              </Typography> 
              <CustomAutoComplete searchData={guardSearch} fullWidth securebooking={true} customSelect={true} currentError={errors}/>
            </Box>
        </Grid>
        <Grid item xs={12} >
            <Box sx={{ position: "relative" }}>
              <Typography mb={2} variant="h5" sx={{ fontWeight: 400, fontSize: { xs: "16px", md: "18px" } }}>
                Start Date & Time
              </Typography> 
              <CustomDatePicker
                  setDate={setDate} 
                  setTime={setTime}
                  errors={errors}
              />                                  
              </Box>
        </Grid>
        <Grid item xs={12} >
              <Box sx={{ position: "relative" }}>
                <Typography   mb={1}  variant="h5" sx={{ fontWeight: 400, fontSize: { xs: "16px", md: "18px" } }}>
                Name
                </Typography> 
                <CustomAutoComplete searchData={userName} fullWidth securebooking={true} customSelect={true} customInput={true} currentError={errors}/>
              </Box>
          </Grid>
          <Grid item xs={12} >
              <Box sx={{ position: "relative" }}>
                <Typography  mb={1}  variant="h5" sx={{ fontWeight: 400, fontSize: { xs: "16px", md: "18px" } }}>
                Email
                </Typography> 
                <CustomAutoComplete searchData={userEmail} fullWidth securebooking={true} customSelect={true} customInput={true}  currentError={errors}/>
              </Box>
          </Grid>
          <Grid item xs={12} >
              <Box sx={{ position: "relative" }}>
                <Typography  mb={1}  variant="h5" sx={{ fontWeight: 400, fontSize: { xs: "16px", md: "18px" } }}>
                Phone
                </Typography> 
                <CustomAutoComplete searchData={userPhone} fullWidth securebooking={true} customSelect={true} customInput={true} currentError={errors}/>
              </Box>
          </Grid>
    
      </Grid>
      
      <div className='d-flex gap-2 justify-content-between p-1 mt-2' style={{height:"10%"}}>
      <Button onClick={handleClose}   variant="contained" style={{  backgroundColor: "#832b67"}}>
        Close
      </Button>
      <Button  variant="contained" color='secondary' type='submit' style={{  backgroundColor: "#2a2f83"}} >
        Submit
      </Button>
      </div>
      </form>
      </>
      :
      <SecureBookingModalLoad type={submitStatus} handleClose={handleClose}/>
}
    </Box>
  </Modal>
  </>
  )
}

export default SecureBookingModel
