import React from 'react'
import { images } from "../config/ConstImg";
import "../styles/securityDetail.css"
import {  FaPhone, FaPhoneAlt } from 'react-icons/fa'
import { IoCall } from 'react-icons/io5';

const SecurityDetail = () => {

  const securityImages=[
    {id:4,image:images.securityimagefour},
    {id:3,image:images.securityimagethree},
    {id:2,image:images.securityimagetwo},
    {id:1,image:images.securityimageone},
  ]
  return (
    <div className='security-detail mt-5' >
      <p className="home-service-content-text-box-txt text-center">
      We Believe in Diversity,  <span className="app-color">Inclusion and Equal Opportunities</span>
      </p>
      <div className='image-container mt-5'>
         <div className='d-flex justify-content-center title-image-con'>
        <img className='img-fluid rounded-3 image-set shadow-lg' src={images.securitytitle} alt=''/>    
        </div> 
        <div className='mt-5'>
            <p className="text-center security-detail-content">
            Need Immediate Security? 
            <span className="app-color"></span>
          </p>
          <p className="security-detail-sub-content text-center">
          Call Our Dispatch Team Now - <b>Fast, Professional Protection at Your Location! 🚨 </b>
          </p>
          <p className='security-detail-phone-number text-center'>
            <a href='tel:0333 577 2477 ' className='phone-link-text app-color' target='_blank'>
            ☎️ 0333 577 2477
            </a>
   
          </p>
        </div>
        <div className='security-images mt-5' >
          {/* <div className='security-images-con'> */}
          {
            securityImages.map((item,index)=>(
              <div key={index} className='image-item'>
              <img className='rounded-3'  src={item.image} alt=''/>    
              </div>
            ))
          }
          {/* </div> */}
        </div>
        <div className='mt-5'>
            <p className="text-center security-detail-content">
            We provide <b>Qualified, Professional, and Affordable Security Staff.</b>
            <span className="app-color"></span>
          </p>
          <p className="security-detail-sub-content text-center">
          <IoCall color='#832b67' size={"1em"} /> Call our dispatch today: <b><a href='tel:0333 577 2477 ' className='phone-link-text app-color' target='_blank'>
            0333 577 2477
            </a></b>
          </p>
          {/* <p className='security-detail-phone-number text-center'>
          <IoCall color='#832b67' size={"1em"} /> 0333 577 2477
          </p> */}
        </div>
      </div> 
    </div>
  )
}

export default SecurityDetail
