import React from 'react'

const SecurityProfessionalTitle = () => {
  return (
    <div className='security-title'>
      <p className="home-service-content-text-box-txt text-center">
      We Have <b>Thousands</b> of Experienced <b>Security Professionals</b>
      </p>         
      <p className="home-service-content-text-box-txt text-center">
      <span className=" ">Available for Immediate Deployment <b>Today!</b></span>
      </p>
      <p className="text-center  app-color contact-text">Call us at <b><a className='app-color phone-link-text' href='tel:0333 577 2477' target='_blank'>0333 577 2477</a></b> for fast, reliable security coverage anywhere in the UK</p>
    </div>
  )
}

export default SecurityProfessionalTitle
