import { Typography } from "@mui/material";
import {  DatePicker, Input, TimePicker,Select } from "antd";
import dayjs from "dayjs";
import { useState } from "react";


export default function CustomDatePicker({
    setDate,setTime,errors
}) {
    const [dateFocused, setDateFocused] = useState(false);
    const [timeFocused, setTimeFocused] = useState(false);
    const disabledDate = (current) => {
        return current && current < dayjs().startOf('day'); // Disable past dates
      };
  return (
    <div style={{ display: 'flex', gap: '15px', width: '100%', flexWrap: 'wrap' }}>
    <div style={{ flex: 1, minWidth: '200px' }}>
        <DatePicker
            style={{ height:"50px",width: '100%',border:"none" ,borderBottom:dateFocused?"2px solid #2a2f83": "2px solid #ccc",boxShadow:"0 2px 8px rgba(0, 0, 0, 0.1)",color:"#000" }}
            format="YYYY-MM-DD"
            placeholder="Select Date"
            className="custom-date-picker search-input"
            onChange={(date)=>setDate(date?.format("YYYY-MM-DD"))}
            onFocus={()=>setDateFocused(true)}
            onBlur={()=>setDateFocused(false)}
            disabledDate={disabledDate}
        />
        {errors["date"]&&
        <Typography variant="h5" mt={2} sx={{ fontWeight: 600, fontSize: { xs: "14px", md: "16px",color:"red" } }}>
        {errors["date"]}
        </Typography>
        }
    </div>

    <div style={{ flex: 1, minWidth: '200px' }}>
        <TimePicker
            style={{  height:"50px",width: '100%',border:"none" ,borderBottom:timeFocused?"2px solid #2a2f83":"2px solid #ccc",boxShadow:"0 2px 8px rgba(0, 0, 0, 0.1)",color:"#000" }}
            format="hh:mm A"
            placeholder="Select Time"
            className="custom-time-picker search-input"
            onChange={(time) => setTime(time?.format('hh:mm A'))}
            onFocus={()=>setTimeFocused(true)}
            onBlur={()=>setTimeFocused(false)}
        />
        {errors["time"]&&
        <Typography variant="h5" mt={2} sx={{ fontWeight: 600, fontSize: { xs: "14px", md: "16px",color:"red" } }}>
        {errors["time"]}
        </Typography>
        }
    </div>
</div>
  );
}
