import homeBg from '../asserts/home-bg.png'
import catImg01 from '../asserts/catImg01.png'
import contentImg from '../asserts/contentImg.png'
import contentBg from '../asserts/contentBg.png'
import guardBg from '../asserts/guardBg.png'
import skill01 from '../asserts/skill.jpg'
import skill02 from '../asserts/skill02.jpeg'
import subCat01 from '../asserts/subCat01.png'
import subCat02 from '../asserts/subCat02.png'
import subCat03 from '../asserts/subCat03.png'
import aboutImg01 from '../asserts/aboutImg01.png'
import aboutImg02 from '../asserts/aboutImg02.png'
import aboutImg03 from '../asserts/aboutImg03.png'
import aboutImg04 from '../asserts/aboutImg04.png'
import aboutImg05 from '../asserts/aboutImg05.jpg'
import aboutImg06 from '../asserts/aboutImg06.png'
import aboutImg07 from '../asserts/aboutImg07.png'
import contant_01 from '../asserts/contant_01.jpg'
import seclist01 from '../asserts/seclist01.jpg'
import seclist02 from '../asserts/seclist02.jpg'
import secitem03 from '../asserts/secitem03.jpg'
import secitem04 from '../asserts/secitem04.jpg'
import secitem05 from '../asserts/secitem05.jpg'
import police01 from '../asserts/police.jpg'
import police02 from '../asserts/police02.jpg'
import police03 from '../asserts/police03.jpg'
import bannerNew from '../asserts/bannerNew.jpeg'
import alert from '../asserts/alert.jpg'
import stripe from '../asserts/stripe.png'
import paypal from '../asserts/paypal.png'
import sideimage from '../asserts/sideimage.jpeg'
import QaImg01 from '../asserts/QaImg01.jpg'
import sos from '../asserts/sos.jpg'
import map from '../asserts/map.jpg'
import hireImg01 from '../asserts/hireImg01.jpg'
import police003 from '../asserts/police03.jpeg'
import works from '../asserts/works.jpg'
import contant_02 from '../asserts/contant_02.jpg'
import sosBtn from '../asserts/sosBtn.jpg'
import cusReview from '../asserts/cusReview.png'
import sos001 from '../asserts/sos01.jpg'
import sos002 from '../asserts/sos01.png'
import choice from '../asserts/Choice.png'
import convenience from '../asserts/Convenience.png'
import Up_front_price from '../asserts/Up_front_price.png'
import professionalism from '../asserts/Professionalism.png'
import safety from '../asserts/Safety.png'
import catImg001 from "../asserts/HomeImg/catImg001.png";
import catImg002 from "../asserts/HomeImg/catImg002.png";
import catImg003 from "../asserts/HomeImg/catImg003.png";
import catImg004 from "../asserts/HomeImg/catImg004.png";
import catImg005 from "../asserts/HomeImg/catImg005.jpg";
import catImg006 from "../asserts/HomeImg/catImg006.png";
import catImg007 from "../asserts/HomeImg/catImg007.jpg";
import catImg008 from "../asserts/HomeImg/catImg008.png";
import catImg009 from "../asserts/HomeImg/catImg009.png";
import catImg010 from "../asserts/HomeImg/catImg010.png";
import catImg011 from "../asserts/HomeImg/catImg011.png";
import catImg012 from "../asserts/HomeImg/catImg012.png";
import catImg013 from "../asserts/HomeImg/catImg013.png";
import catImg014 from "../asserts/HomeImg/catImg014.png";
import catImg015 from "../asserts/HomeImg/catImg015.png";
import whatsApp from "../asserts/whatsapp.svg";
import igReg from "../asserts/ig-reg.png";
import payas from "../asserts/payas.png";
import connect from "../asserts/connect.png";
import chain from "../asserts/chain.jpeg";
import clean from "../asserts/clean.png";
import circleimg from "../asserts/circleimg.png";
import doghandler from "../asserts/doghandler.png";
import doghandling from "../asserts/doghandling.png";
import founder from "../asserts/founder.png";
import fraud from "../asserts/fraud.png";
import stop from "../asserts/stop.png";
import securitytitle from "../asserts/security_title.jpg"
import securityimageone from "../asserts/security_one.jpg"
import securityimagetwo from "../asserts/security_two.jpg"
import securityimagethree from "../asserts/security_three.jpg"
import securityimagefour from "../asserts/security_four.jpg"
import googlePlayStoreIcon from "../asserts/googleplay_.png"
import appStoreIcon from "../asserts/apple.jpg"


export const images = {
    homeBg,
    catImg01,
    contentImg,
    contentBg,
    guardBg,
    skill01,
    skill02,
    subCat01,
    subCat02,
    subCat03,
    aboutImg01,
    aboutImg02,
    aboutImg03,
    aboutImg04,
    contant_01,
    seclist01,
    seclist02,
    secitem03,
    secitem04,
    police01,
    police02,
    police03,
    bannerNew,
    alert,
    stripe,
    paypal,
    sideimage,
    secitem05,
    QaImg01,
    sos,
    map,
    hireImg01,
    aboutImg05,
    aboutImg06,
    aboutImg07,
    police003,
    works,
    contant_02,
    sosBtn,
    cusReview,
    sos001,
    sos002,
    choice,
    convenience,
    Up_front_price,
    professionalism,
    safety,
    catImg001,
    catImg002,
    catImg003,
    catImg004,
    catImg005,
    catImg006,
    catImg007,
    catImg008,
    catImg009,
    catImg010,
    catImg011,
    catImg012,
    catImg013,
    catImg014,
    catImg015,
    whatsApp,
    igReg,
    payas,
    connect,
    chain,
    clean,
    circleimg,
    doghandler,
    doghandling,
    founder,
    fraud,
    stop,
    securitytitle,
    securityimageone,
    securityimagetwo,
    securityimagethree,
    securityimagefour,
    googlePlayStoreIcon,
    appStoreIcon
}