import { Button } from '@mui/material'
import { Grid } from "antd";

const BookingButton = ({setSecureBookingModal,secureBookingModal,questionModal}) => {
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();
    const isMobile = screens.xs || screens.sm; 
    const isLaptop = screens.md || screens.lg || screens.xl;
  return (
    !secureBookingModal && !questionModal?
         <Button
                onClick={()=>setSecureBookingModal((prev)=>true)}
                variant="contained"
                // title='Secure Your Booking Now!'
                sx={{
                  // width: { xs: "auto", md: "auto" },
                  left:"0",
                  top:"35%",
                  zIndex:999,
                  position:"fixed",
                  minHeight: "50px",
                  marginLeft:"10px",
                  marginTop:"-8px",
                  borderRadius: "5px",
                  // fontSize: "16px",
                  boxShadow: 2,
                  backgroundColor: "#832b67",
                  color: "#fff",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#832b67",
                  },
                }}
              >
              {isLaptop?"Secure Your Booking Now!":isMobile?<span>
                Secure Your <br/> Booking Now!
              </span>:""}
        </Button>
    :null
  )
}

export default BookingButton
